import React from "react"
import PageBanner from "../components/Common/PageBanner"
import { GetStartedWithUs } from "../components/Common/GetStartedWithUs"
import { SEO_TITLES } from "../config/seo"

import { EMOJIS } from "../config/emojis"

import { CommonHeaderAndFooter } from "../layout/CommonHeaderAndFooter"
import { COMPANY_NAME } from "../config/common"

const FEATURES_PAGE_VARIANT = {
  ONE: {
    banner: {
      heading: "Features",
      subheading: `Dive deep into all the features that ${COMPANY_NAME} has to offer`
    },
    pricingSection: {
      heading: null,
      // heading: `Find the perfect plan for your company`
    }
  }
}

const Features = ({ variant }) => {
  variant = FEATURES_PAGE_VARIANT.ONE
  return (
    <CommonHeaderAndFooter seoTitle={SEO_TITLES.VARIANT3.FEATURES}>
      <PageBanner
        pageTitle={variant.banner.heading}
        variant={variant}
        showShapes={false}
        compact
      />
      {variant.pricingSection.heading && (
        <div className="pr-5 pl-5 bg-color-grey">
          <h2 className="d-flex justify-content-center pt-5 pb-5 bg-color-grey mb-0">
            {variant.pricingSection.heading} {EMOJIS.ROCKET({ className: "ml-2 d-none d-lg-block", size: 40 })}
          </h2>
        </div>
      )}
      <GetStartedWithUs />
    </CommonHeaderAndFooter>
  )
}

export default Features